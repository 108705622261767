import { fetchWrapper } from './auth';
import { UPDATE_STATE } from '../state/constants';

export const getXLVehicleType = () => {
  return 'truck';
};

export const createRequest = async (body) => {
  console.log(body);
  const cleanBody = {
    ...body,
  };
  const response = await fetchWrapper('/orders', {
    method: 'POST',
    body: JSON.stringify(cleanBody),
  });
  if (!response.ok) {
    throw await response.json();
  }
};

export const validateRequest = async (body) => {
  const response = await fetchWrapper('/addresses/validate', {
    method: 'POST',
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    throw await response.json();
  }
  const { response: result } = await response.json();
  return result;
};

export const loadRequests = async (dispatch, status, ...args) => {
  try {
    let params = new URLSearchParams({ status: status });
    if (status === 'scheduled') {
      params = new URLSearchParams({
        status: 'scheduled',
        search: args[0],
        sort: 'pickup_at asc',
      });
    } else if (status === 'ongoing') {
      params = new URLSearchParams({
        status: 'ongoing',
        sort: 'created_at desc',
      });
    }

    const response = await fetchWrapper(`/orders?${params.toString()}`);
    const jsonResponse = await response.json();
    if (!response.ok) throw jsonResponse;
    const { response: requests } = jsonResponse;
    dispatch({ type: UPDATE_STATE, payload: { [status]: requests } });
  } catch (e) {}
};

export const loadRequestsHistory = async (page, per_page, search) => {
  try {
    const params = new URLSearchParams({
      status: 'history',
      page,
      per_page,
      search,
      sort: 'ended_at desc',
    });
    const response = await fetchWrapper(`/orders?${params.toString()}`);
    const jsonResponse = await response.json();
    if (!response.ok) throw jsonResponse;
    const { response: requests } = jsonResponse;
    const total = response.headers.get('X-Total');
    return { requests, total: isNaN(total) ? requests.length : total };
  } catch (e) {
    return { requests: [], total: 0 };
  }
};

export const loadRequest = async (id) => {
  const response = await fetchWrapper(`/orders/${id}`);
  if (!response.ok) {
    throw await response.json();
  }
  const { response: request } = await response.json();
  return request;
};

export const cancelRequest = async (entity, id, public_reason_key, comment) => {
  const response = await fetchWrapper(`/cancel/${entity}/${id}`, {
    method: 'POST',
    body: JSON.stringify({ public_reason_key, comment }),
  });
  if (!response.ok) {
    throw await response.json();
  }
};

export const getCancelStatus = async (entity, id) => {
  const response = await fetchWrapper(`/cancel/${entity}/${id}/status`);
  const jsonResponse = await response.json();
  if (!response.ok) {
    throw jsonResponse;
  }
  return jsonResponse.response;
};

export const editDelivery = async (orderId, deliveryId, value, type) => {
  const { address, client_reference, package_description, ...newValue } = value;
  const body = {
    job: {
      deliveries: [
        {
          id: deliveryId,
          client_reference,
          package_description,
          [type]: newValue,
        },
      ],
    },
  };
  const response = await fetchWrapper(`/orders/${orderId}`, {
    method: 'PATCH',
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    throw await response.json();
  }
};

export const sendAddress = async (body) => {
  const { client_reference, package_description, ...data } = body;
  const response = await fetchWrapper('/addresses', {
    method: 'POST',
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw await response.json();
  }
};

export const editAddress = async ({ id, ...body }) => {
  const response = await fetchWrapper(`/addresses/${id}`, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    throw await response.json();
  }
};

export const loadAddresses = async (dispatch) => {
  const response = await fetchWrapper('/addresses');
  if (!response.ok) {
    throw await response.json();
  }
  const { response: addresses } = await response.json();
  dispatch({ type: UPDATE_STATE, payload: { addresses: addresses || [] } });
};

export const deleteAddress = async (id) => {
  const response = await fetchWrapper(`/addresses/${id}`, {
    method: 'DELETE',
  });
  if (!response.ok) {
    throw await response.json();
  }
};

export const getSlot = async ({ region, date }) => {
  const res = await fetchWrapper(`/slots/${region}/dropoff/${date}`, {
    method: 'GET',
  });

  if (!res) {
    throw new Error('Error getting slots');
  }

  if (!res.ok) {
    throw await res.json();
  }
  const { response } = await res.json();

  return response;
};
