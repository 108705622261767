import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import DynamicInput from '../DynamicInput';
import { ToastContainer, toast, Slide } from 'react-toastify';
import Form from 'react-bootstrap/Form';

import Address from '../Address';
import OrderDate from '../OrderDate';
import OrderVehicle from '../OrderVehicle';
import OrderOptions from '../OrderOptions';

import {
  getTruckPrice,
  getOtherVehiclesPrices,
  loadPricingAttributes,
} from '../../utilities/pricing';
import {
  getDistance,
  infoWindowTemplate,
  initMap,
  getPlaceLocation,
  getAddressLocation,
} from '../../utilities/map';
import {
  createRequest,
  sendAddress,
  loadAddresses,
  getXLVehicleType,
} from '../../utilities/requests';
import { GlobalStateContext } from '../../state';
import { toastOptions } from '../../state/constants';

import './Request.css';
import 'react-toastify/dist/ReactToastify.css';

const defaultAddressQuery = { address: '', contact: {} };
const defaultPrice = {
  currency: 'EUR',
  price_tax_excluded: 0,
  price_tax_included: 0,
  tax_amount: 0,
  tax_percentage: 0,
};

const truckConfigErreur = 'truckConfigError: errorLoadingPricingAttributes';

const travelModes = {
  walk: 'WALKING',
  bike: 'BICYCLING',
  motorbike: 'DRIVING',
  cargobike: 'DRIVING',
  car: 'DRIVING',
  van: 'DRIVING',
  truck: 'DRIVING',
};

let currentId = 1;

const Input = (props) => {
  return (
    <Form.Group controlId={props.controlId}>
      <Form.Label className='dynamicinput-label'>{props.name}</Form.Label>
      <Form.Control
        onChange={props.onChange}
        readOnly={props.readOnly}
        disabled={props.readOnly}
        value={props.value}
      />
    </Form.Group>
  );
};

const defaultOptions = [{ dropoffId: currentId, options: [] }];
const defaultPickupDrawing = { marker: null, icon: '/pickup-marker.svg' };
const defaultDropoffDrawing = {
  marker: null,
  icon: '/dropoff-marker.svg',
  infoWindow: null,
  placeId: null,
  directionsRenderer: null,
};

const xlVhType = getXLVehicleType();

const Request = () => {
  const { t } = useTranslation();

  const [state, { dispatch, googleLoaded }] = useContext(GlobalStateContext);
  const [pricingAttributes, setPricingAttributes] = useState([]);
  const [pickup, setPickup] = useState(defaultAddressQuery);
  const [dropoffs, setDropoffs] = useState([
    { id: currentId, ...defaultAddressQuery, package_type: 'xxlarge' },
  ]);
  const [pricingCriterias, setPricingCriterias] = useState([]);
  const [options, setOptions] = useState(defaultOptions);
  const [distances, setDistances] = useState([]);
  const [travelMode, setTravelMode] = useState('DRIVING');
  const [truckPrice, setTruckPrice] = useState(defaultPrice);
  const [otherVehiclesPrices, setOtherVehiclesPrices] = useState({});
  const [allPrices, setAllPrices] = useState({
    [xlVhType]: { amount: truckPrice, success: true },
    ...otherVehiclesPrices,
  });
  const [price, setPrice] = useState(truckPrice);
  const [pricePerDelivery, setPricePerDelivery] = useState([]);
  const [transportType, setTransportType] = useState('truck');
  const [pickupAt, setPickupAt] = useState(null);
  const [isCreatingRequest, setIsCreatingRequest] = useState(false);
  const [isLoadingTruckPrice, setIsLoadingTruckPrice] = useState(false);
  const [isLoadingOtherPrices, setIsLoadingOtherPrices] = useState(false);
  const [missingPricingCriterias, setMissingPricingCriterias] = useState(false);
  const [formattedJob, setFormattedJob] = useState({});
  const [formattedOrder, setFormattedOrder] = useState({});
  const isPricingAttributesLoaded = useRef(false);
  const errorLoadingAttributes = useRef(false);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const pickupDrawing = useRef(defaultPickupDrawing);
  const dropoffDrawings = useRef([
    { ...defaultDropoffDrawing, dropoffId: currentId },
  ]);
  const defaultPricingCriteria = useRef({ distance: '0' });
  const canReloadPrice = useRef(false);
  const history = useHistory();
  const drawItinerary = useRef(true);
  const dropoffAdded = useRef(false);
  const dropoffRefs = useRef([]);

  const isValid = pickup.isValid && dropoffs.every((d) => d.isValid);
  const isLoadingPrice = isLoadingTruckPrice || isLoadingOtherPrices;
  const priceIsValid = !!price.price_tax_excluded;
  const canSelectVehicle = !isLoadingPrice && !isCreatingRequest;
  const canEditOptions = transportType === xlVhType;

  const submitEnabled =
    (!canEditOptions || !missingPricingCriterias) &&
    isValid &&
    !isLoadingPrice &&
    !isCreatingRequest &&
    priceIsValid &&
    isPricingAttributesLoaded.current;

  const setRef = (ref) => {
    if (dropoffAdded.current) {
      dropoffRefs.current.push(ref);
    }
  };

  const fitBounds = () => {
    try {
      const bounds = new window.google.maps.LatLngBounds();
      const dropoffMarkers = dropoffDrawings.current.map((d) => d.marker);
      const markers = [pickupDrawing.current.marker, ...dropoffMarkers];
      markers.forEach((marker) => {
        if (marker) bounds.extend(marker.getPosition());
      });
      map.current.fitBounds(bounds);
      map.current.panToBounds(bounds);
    } catch (e) {
      console.error(e);
    }
  };

  const getPlaceIdLocation = async (placeId, address) => {
    return placeId
      ? await getPlaceLocation(placeId, map.current)
      : await getAddressLocation(address);
  };

  const updateDrawing = async (
    drawing,
    { address, placeId },
    distanceInfo,
    index
  ) => {
    if (drawing.marker) drawing.marker.setMap(null);
    if (!address.length) return;
    const location = await getPlaceIdLocation(placeId, address);
    drawing.marker = new window.google.maps.Marker({
      position: location,
      map: map.current,
      icon: drawing.icon,
    });
    fitBounds();
    if (distanceInfo) {
      updateDrawingInfo(drawing, distanceInfo, index);
    }
  };

  const updateDrawingInfo = (drawing, distanceInfo, index) => {
    if (!distanceInfo || !drawing.marker) {
      return;
    }

    if (drawing.directionsRenderer) {
      drawing.directionsRenderer.setMap(null);
    }

    if (distanceInfo.directionResult && drawItinerary.current) {
      const options = { markerOptions: { visible: false } };
      if (!drawing.directionsRenderer) {
        drawing.directionsRenderer = new window.google.maps.DirectionsRenderer(
          options
        );
      }
      drawing.directionsRenderer.setMap(map.current);
      drawing.directionsRenderer.setDirections(distanceInfo.directionResult);
    }

    if (distanceInfo.distance) {
      if (!drawing.infoWindow)
        drawing.infoWindow = new window.google.maps.InfoWindow();
      else drawing.infoWindow.close();
      drawing.infoWindow.setContent(
        infoWindowTemplate(distanceInfo.distance, index)
      );
      drawing.infoWindow.setPosition(drawing.marker.getPosition());
      drawing.infoWindow.open(map.current, drawing.marker);
    }
  };

  const updateDrawingsInfo = () => {
    const lenDrawings = dropoffDrawings.current.length;
    for (var i = 0, len = lenDrawings; i < len; i++) {
      const drawing = dropoffDrawings.current[i];
      updateDrawingInfo(
        drawing,
        distances.find((dd) => dd.dropoffId === drawing.dropoffId),
        i
      );
    }
  };

  const updateDropoffDrawing = (dropoff, distanceInfo) => {
    const lendrawings = dropoffDrawings.current.length;
    if (!drawItinerary.current && lendrawings) {
      dropoffDrawings.current[0].directionsRenderer?.setMap(null);
    }

    const drawingIndex = dropoffDrawings.current.findIndex(
      (d) => d.dropoffId === dropoff.id
    );
    const drawing = dropoffDrawings.current[drawingIndex];
    updateDrawing(drawing, dropoff, distanceInfo, drawingIndex);
  };

  const removeDropoffDrawing = (dropoffId) => {
    const drawing = dropoffDrawings.current.find(
      (dd) => dd.dropoffId === dropoffId
    );
    drawing.directionsRenderer?.setMap(null);
    drawing.infoWindow?.close();
    drawing.marker?.setMap(null);
    dropoffDrawings.current = dropoffDrawings.current.filter(
      (dd) => dd.dropoffId !== dropoffId
    );
    drawItinerary.current = dropoffDrawings.current.length === 1;
    updateDrawingsInfo();
  };

  const addDropOffDistance = (dropoffId) => {
    setDistances([
      ...distances,
      { dropoffId, distance: '0', directionResult: null },
    ]);
  };

  const updateDropoffDistance = (dropoffId, distanceInfo) => {
    setDistances(
      distances.map((dd) =>
        dd.dropoffId !== dropoffId
          ? dd
          : {
              ...dd,
              distance: distanceInfo.distance,
              directionResult: distanceInfo.directionResult,
            }
      )
    );
  };

  const updatePricingCriteria = (dropoffId, attName, newValue) => {
    canReloadPrice.current = true;
    setPricingCriterias(
      pricingCriterias.map((pc) =>
        pc.dropoffId !== dropoffId
          ? pc
          : {
              ...pc,
              [attName]: newValue,
            }
      )
    );
  };

  const getPurePricingCriterias = (unpure) =>
    unpure.map((pc) => {
      const { dropoffId, ...pure } = pc;
      return { ...pure };
    });

  const getPricingCriteriaByDropoffId = useCallback(
    (id) => {
      const pricingCriteria = pricingCriterias.find(
        (pc) => pc.dropoffId === id
      );
      const { dropoffId, ...pure } = pricingCriteria || {};
      return pure;
    },
    [pricingCriterias]
  );

  const getPureAddress = (address) => {
    const { isValid, save, placeId, id, collapsed, ...pure } = address;
    return pure;
  };
  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
  }, []);
  useEffect(() => {
    setFormattedJob({
      pickups: [getPureAddress(pickup)],
      dropoffs: dropoffs.map(getPureAddress),
    });

    if (dropoffAdded.current) {
      dropoffRefs.current[dropoffRefs.current.length - 1].scrollIntoView();
      dropoffAdded.current = false;
      dropoffRefs.current = [];
    }
  }, [dropoffs, pickup]);

  useEffect(() => {
    setFormattedOrder({
      job: {
        ...formattedJob,
        transport_type: transportType,
        pickup_at: pickupAt,
      },
      pricing_info: {
        price,
        deliveries: dropoffs.map((dropoff, i) => ({
          pricing_criteria: {
            ...getPricingCriteriaByDropoffId(dropoff.id),
          },
          options: (
            options.find((option) => option.dropoffId === dropoff.id) || {
              options: [],
            }
          ).options,
          price: null,
        })),
      },
    });
  }, [
    dropoffs,
    formattedJob,
    getPricingCriteriaByDropoffId,
    options,
    price,
    pickupAt,
    transportType,
  ]);

  const updateDistance = async (dropoff) => {
    const distanceInfo = await getDistance(
      pickup.address,
      dropoff.address,
      travelMode,
      drawItinerary.current
    );
    updateDropoffDistance(dropoff.id, distanceInfo);
    updatePricingCriteria(dropoff.id, 'distance', distanceInfo.distance);
    updateDropoffDrawing(dropoff, distanceInfo);
  };

  const updateAllDistances = async (newPickup) => {
    const newDistances = await Promise.all(
      dropoffs.map(async (dropoff) => {
        const distanceInfo = await getDistance(
          newPickup.address,
          dropoff.address,
          travelMode,
          drawItinerary.current
        );
        updateDropoffDrawing(dropoff, distanceInfo);
        return { ...distanceInfo, dropoffId: dropoff.id };
      })
    );
    setPricingCriterias(
      pricingCriterias.map((pc) => ({
        ...pc,
        distance: newDistances.find((d) => d.dropoffId === pc.dropoffId)
          .distance,
      }))
    );
    setDistances(newDistances);
  };

  const updatePickup = async (value) => {
    canReloadPrice.current = true;
    setPickup(value);
    const addressChanged = value.address !== pickup.address;
    if (addressChanged) {
      updateDrawing(pickupDrawing.current, value);
      canReloadPrice.current = true;
      updateAllDistances(value);
    } else {
      canReloadPrice.current = false;
    }
  };

  const updateDropoff = (id) => (value) => {
    const newValue = { ...value, id, package_type: 'xxlarge' };
    setDropoffs(
      dropoffs.map((dropoff) => (dropoff.id === id ? newValue : dropoff))
    );
    const oldValue = dropoffs.find((dropoff) => dropoff.id === id);
    const addressChanged = newValue.address !== oldValue.address;
    if (addressChanged) {
      canReloadPrice.current = true;
      updateDistance(newValue);
    } else {
      canReloadPrice.current = false;
    }
  };

  const removeDropoff = (id) => (e) => {
    const newDropoffs = dropoffs.filter((dropoff) => dropoff.id !== id);
    if (newDropoffs.length === 1) {
      newDropoffs[0].collapsed = false;
    }
    setDropoffs(newDropoffs);
    setDistances(distances.filter((dd) => dd.dropoffId !== id));
    setPricingCriterias(pricingCriterias.filter((pc) => pc.dropoffId !== id));
    setOptions(options.filter((o) => o.dropoffId !== id));
    removeDropoffDrawing(id);
    canReloadPrice.current = true;
  };

  const addPricingInfos = useCallback(
    (currentPC, currentOptions, dropoffId) => {
      setPricingCriterias([
        ...currentPC,
        { dropoffId, ...defaultPricingCriteria.current },
      ]);
      setOptions([...currentOptions, { dropoffId, options: [] }]);
    },
    []
  );

  const missingAttribute = (pc, attributes) => {
    for (let attribute of attributes) {
      if (!(attribute.name in pc)) return true;
    }
    return false;
  };

  const addDropoff = () => {
    const newDropOffs = [
      ...dropoffs.map((dropoff) => ({
        ...dropoff,
        collapsed: dropoff.address.length > 0,
        package_type: 'xxlarge',
      })),
      { id: ++currentId, collapsed: false, ...defaultAddressQuery },
    ];
    setDropoffs(newDropOffs);
    addDropOffDistance(currentId);
    addPricingInfos(pricingCriterias, options, currentId);
    dropoffDrawings.current.push({
      ...defaultDropoffDrawing,
      dropoffId: currentId,
    });
    drawItinerary.current = false;
    dropoffAdded.current = true;
  };

  const toggleCollapse = (dropoffId) => (collapse) => () => {
    canReloadPrice.current = false;
    const stateOfOther = (other) =>
      collapse ? other.collapsed : other.address.length > 0;
    setDropoffs(
      dropoffs.map((dropoff) => {
        return {
          ...dropoff,
          collapsed:
            dropoff.id === dropoffId ? collapse : stateOfOther(dropoff),
        };
      })
    );
  };

  useEffect(() => {
    if (canReloadPrice.current) {
      const job = formattedJob;
      const emptyAdress = job.dropoffs.find(
        (dropoff) => dropoff.address === ''
      );
      if (!emptyAdress) {
        setIsLoadingOtherPrices(true);

        getOtherVehiclesPrices({ job })
          .then(setOtherVehiclesPrices)
          .finally(() => {
            setIsLoadingOtherPrices(false);
          });
      }
    }
  }, [formattedJob]);

  useEffect(() => {
    const purePricingCriterias = getPurePricingCriterias(pricingCriterias);
    for (let purePc of purePricingCriterias) {
      if (missingAttribute(purePc, pricingAttributes)) {
        setMissingPricingCriterias(true);
        return;
      }
    }
    setMissingPricingCriterias(false);
    if (
      purePricingCriterias.length &&
      canReloadPrice.current &&
      !errorLoadingAttributes.current
    ) {
      const deliveries = purePricingCriterias.map((pc) => ({
        pricing_criteria: pc,
      }));
      setIsLoadingTruckPrice(true);
      getTruckPrice({ deliveries })
        .then((response) => {
          setTruckPrice(response.price);
          setPricePerDelivery(response.deliveries);
        })
        .finally(() => {
          setIsLoadingTruckPrice(false);
        });
    }
  }, [pricingCriterias, pricingAttributes]);

  useEffect(() => {
    if (transportType) setTravelMode(travelModes[transportType]);
  }, [transportType]);

  useEffect(() => {
    let newTruckPrice = { amount: truckPrice, success: true };
    if (errorLoadingAttributes.current)
      newTruckPrice = {
        amount: truckPrice,
        success: false,
        error: truckConfigErreur,
      };
    setAllPrices({
      [xlVhType]: newTruckPrice,
      ...otherVehiclesPrices,
    });
  }, [truckPrice, otherVehiclesPrices]);

  useEffect(() => {
    if (transportType) setPrice(allPrices[transportType].amount);
  }, [allPrices, transportType]);

  const setOption = (dropoffId) => (option, checked) => {
    setOptions(
      options.map((o) =>
        o.dropoffId !== dropoffId
          ? o
          : {
              ...o,
              options: [...o.options, option].filter(
                (o) => o !== option || checked
              ),
            }
      )
    );
  };

  useEffect(() => {
    document.title = `${t('newDelivery')} | XL EDS`;
  });

  useEffect(() => {
    loadPricingAttributes()
      .then((attributes) => {
        const pricingAttributes = attributes.filter(
          (a) => a.name !== 'distance'
        );
        setPricingAttributes(pricingAttributes);
        addPricingInfos([], [], currentId);
        if (!state.user.UserConfig.EnableVehicleTypeSelection.Enabled) {
          setTransportType(xlVhType);
        }
        errorLoadingAttributes.current = false;
      })
      .catch(() => {
        toast.error(t('errorLoadingPricingAttributes'), toastOptions);
        errorLoadingAttributes.current = true;
        setAllPrices({
          [xlVhType]: {
            amount: truckPrice,
            success: false,
            error: truckConfigErreur,
          },
          ...otherVehiclesPrices,
        });
      })
      .finally(() => {
        isPricingAttributesLoaded.current = true;
      });
  }, [state.user, addPricingInfos]);

  useEffect(() => {
    if (!window.google) return;
    initMap(map, mapContainer);
  }, [googleLoaded]);

  useEffect(() => {
    loadAddresses(dispatch);
  }, [dispatch]);

  const sendRequest = async () => {
    setIsCreatingRequest(true);
    try {
      await createRequest({
        ...formattedOrder,
        job: {
          ...formattedOrder.job,
          dropoffs: [
            ...formattedOrder.job.dropoffs.map((dropoff) => ({
              ...dropoff,
              // loop packageDescript and append key: value
              package_description:
                `${dropoff.package_description}  ` +
                packageDescription
                  .map((e) => `${e.key}: ${e.value}`)
                  .join(', '),
            })),
          ],
        },
      });
      setIsCreatingRequest(false);
      const toastMsg = t('successToast');
      const nextPage = pickupAt ? '/scheduled' : '/ongoing';
      const options = {
        ...toastOptions,
        autoClose: 2000,
        onClose: () => {
          history.push(nextPage);
        },
      };
      toast.success(toastMsg, options);
      if (pickup.save) sendAddress(getPureAddress(pickup));
      dropoffs.forEach((dropoff) => {
        if (dropoff.save) sendAddress(getPureAddress(dropoff));
      });
    } catch (response) {
      setIsCreatingRequest(false);
      const errorMessage = response.error.message
        .split(': ')
        .pop()
        .replaceAll('.', ' ');
      toast.error(t(errorMessage), toastOptions);
    }
  };

  const formatPrice = (price) => {
    const { price_tax_excluded, price_tax_included } = price;
    const htPrice = price_tax_excluded.toFixed(2).replace('.', ',');
    const ttcPrice = price_tax_included.toFixed(2).replace('.', ',');
    const htMsg = t('tax_excluded');
    const ttcMsg = t('tax_included');
    return `${htPrice}€ ${htMsg} (${ttcPrice}€ ${ttcMsg})`;
  };

  const [packageDescription, setPackageDescription] = useState([]);

  return (
    <div className='request-page'>
      <div className='request-mapcontainer'>
        <div ref={mapContainer} className='request-mapelement'></div>
      </div>
      <div className='request-formcontainer'>
        <Container>
          <br />
          <Row>
            <Col xs='12' md={{ span: 10, offset: 1 }}>
              <Container className='App-default-component'>
                <Address
                  id='pickup-component'
                  type='picking'
                  setter={updatePickup}
                  address={t('pickup')}
                  iconSrc='/pickup-icon.svg'
                  illustration='/pickup-illustration.svg'
                />
              </Container>
            </Col>
          </Row>
          <br />
          {dropoffs.map((dropoff, index) => (
            <div ref={setRef} key={dropoff.id}>
              <Row>
                <Col xs='12' md={{ span: 10, offset: 1 }}>
                  <Container className='App-default-component'>
                    <Address
                      id={`dropoff-component-${dropoff.id}`}
                      index={index}
                      type='delivering'
                      setter={updateDropoff(dropoff.id)}
                      address={t('dropoff')}
                      iconSrc='/dropoff-icon.svg'
                      illustration='/dropoff-illustration.svg'
                      showDetails
                      collapsable={dropoffs.length > 1}
                      collapsed={dropoff.collapsed && dropoffs.length > 1}
                      toggleCollapse={toggleCollapse(dropoff.id)}
                      removable={index}
                      onRemove={removeDropoff(dropoff.id)}
                      pricingCriteria={pricingCriterias.find(
                        (pc) => pc.dropoffId === dropoff.id
                      )}
                      deliveryPrice={pricePerDelivery[index]?.price}
                      transportType={transportType}
                    />
                    <br />
                    {canEditOptions &&
                      pricingAttributes.length > 0 &&
                      !dropoff.collapsed && (
                        <Container>
                          <Row>
                            <Col>
                              <h4>{t('pricingCriterias')}</h4>
                            </Col>
                          </Row>
                          <br />
                          {pricingAttributes.map((pa, index) => (
                            <Row key={index}>
                              <Col>
                                <DynamicInput
                                  controlId={pa.name}
                                  name={pa.name}
                                  type={pa.type}
                                  options={pa.options}
                                  readOnly={!canEditOptions}
                                  value={
                                    getPricingCriteriaByDropoffId(dropoff.id)[
                                      pa.name
                                    ] || ''
                                  }
                                  onChange={(e) => {
                                    updatePricingCriteria(
                                      dropoff.id,
                                      pa.name,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          ))}
                          <br />
                        </Container>
                      )}
                    {state.user.UserConfig.OrderOptions.length > 0 &&
                      canEditOptions &&
                      !dropoff.collapsed && (
                        <div>
                          <OrderOptions
                            setter={setOption(dropoff.id)}
                            list={state.user.UserConfig.OrderOptions}
                            value={
                              options.find((o) => o.dropoffId === dropoff.id)
                                .options
                            }
                          />
                          <br />
                        </div>
                      )}
                    <Container>
                      <Row>
                        <Col>
                          {state.user.UserConfig.AdditionalInfos &&
                            state.user.UserConfig.AdditionalInfos.map(
                              (e, index) => {
                                return (
                                  <Input
                                    required={false}
                                    controlId={e}
                                    value={
                                      packageDescription[index]?.value ?? ''
                                    }
                                    name={e}
                                    onChange={({ target }) => {
                                      setPackageDescription([
                                        ...packageDescription.filter(
                                          (_, i) => i !== index
                                        ),
                                        { value: target.value, key: e },
                                      ]);
                                    }}
                                    type='text'
                                  />
                                );
                              }
                            )}
                        </Col>
                      </Row>
                    </Container>
                  </Container>
                </Col>
              </Row>
              <br />
            </div>
          ))}
          {state.user.UserConfig.EnableVehicleTypeSelection.Enabled && (
            <div>
              <br />
              <Row>
                <Col xs='12' md={{ span: 10, offset: 1 }}>
                  <OrderVehicle
                    setter={setTransportType}
                    value={transportType}
                    prices={allPrices}
                    showPrice={state.user.UserConfig.ShowPricing}
                    defaultAmount={defaultPrice}
                    disabled={!canSelectVehicle}
                    collapsed={dropoffs.length > 1}
                    enabledTypes={state.user.UserConfig.EnableVehicleTypeSelection.EnabledTypes.filter(
                      (enableVehicle) => enableVehicle === 'truck'
                    )}
                  />
                </Col>
              </Row>
            </div>
          )}
          <br />
          {state.user.UserConfig.EnableStackedDeliveries &&
            dropoffs.length < 8 && (
              <div>
                <Row>
                  <Col></Col>
                  <Col xs='auto'>
                    <Button variant='info' onClick={addDropoff}>
                      <FontAwesomeIcon icon={faPlusSquare} />
                      &nbsp;
                      {t('addDropOff')}
                    </Button>
                  </Col>
                  <Col></Col>
                </Row>
                <br />
              </div>
            )}
          <Row>
            <Col xs='12' md={{ span: 10, offset: 1 }}>
              <OrderDate
                setter={setPickupAt}
                value={pickupAt}
                address={formattedJob.dropoffs?.[0]?.address ?? ''}
              />
            </Col>
          </Row>
          <br />
          <br />
          <br />
        </Container>
        <div className='request-pricerow'>
          <Button
            id='submit-request'
            variant='info'
            onClick={sendRequest}
            disabled={!submitEnabled}
          >
            {isCreatingRequest && <span> {t('creatingRequest')} </span>}
            {(isCreatingRequest || isLoadingPrice) && (
              <span>
                <FontAwesomeIcon icon='spinner' pulse />
              </span>
            )}
            {!isCreatingRequest && !isLoadingPrice && (
              <span>
                <span>{t('submitRequest')} </span>
                {state.user.UserConfig.ShowPricing && (
                  <span>
                    {' '}
                    {t('for')} {formatPrice(price)}{' '}
                  </span>
                )}
              </span>
            )}
          </Button>
        </div>
      </div>
      <ToastContainer transition={Slide} />
    </div>
  );
};

Request.propTypes = {};

Request.defaultProps = {};

export default Request;
