import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserMenuItem from '../UserMenuItem';
import { logout, loadUserData } from '../../utilities/auth';
import { GlobalStateContext } from '../../state';
import { NavLink } from 'react-router-dom';

import './UserMenu.css';
import { Button } from 'react-bootstrap';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    className='usermenu-toggle'
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

const UserMenu = () => {
  const { t } = useTranslation();

  const [state, { dispatch }] = useContext(GlobalStateContext);

  useEffect(() => {
    loadUserData(dispatch);
  }, [dispatch]);

  const triggerLogout = (e) => {
    logout(dispatch);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle}>
        <div className='usermenu-avatar'>
          <FontAwesomeIcon className='usermenu-icon' icon='user' size='lg' />
        </div>
        <FontAwesomeIcon icon='chevron-down' size='xs' />
      </Dropdown.Toggle>
      <Dropdown.Menu alignRight>
        <Dropdown.Header>{state.user.Name}</Dropdown.Header>
        <Dropdown.Divider />

        <UserMenuItem icon='cog' as={NavLink} to='/settings'>
          {t('settings')}
        </UserMenuItem>
        <UserMenuItem icon='sign-out-alt' onClick={triggerLogout}>
          {t('logout')}
        </UserMenuItem>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserMenu;
