import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';

import UserMenu from '../UserMenu';
import { GlobalStateContext } from '../../state';
import { loadRequests } from '../../utilities/requests';

import './Header.css';
import { Button } from 'react-bootstrap';

const Header = () => {
  const { t } = useTranslation();
  const [state, { dispatch }] = useContext(GlobalStateContext);

  useEffect(() => {
    loadRequests(dispatch, 'ongoing');
    loadRequests(dispatch, 'scheduled');
  }, [dispatch]);

  return (
    <Navbar className='header-navbar' bg='white' fixed='top'>
      <Navbar.Brand as={NavLink} to='/'>
        <img alt='Stuart Logo' src='/xl_eds_logo.png' height='40' />
      </Navbar.Brand>
      <Navbar.Collapse>
        <Nav className='header-nav'>
          <Nav.Link
            as={NavLink}
            activeClassName='active'
            exact
            to='/'
            className='header-mainlink header-navlink'
          >
            {t('requestDelivery')}
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            activeClassName='active'
            className='header-navlink'
            to='/ongoing'
          >
            {t('ongoing')}
            <sup>
              {state.ongoing &&
                state.ongoing.length > 0 &&
                state.ongoing.length}
            </sup>
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            activeClassName='active'
            className='header-navlink'
            to='/scheduled'
          >
            {t('scheduled')}
            <sup>
              {state.scheduled &&
                state.scheduled.length > 0 &&
                state.scheduled.length}
            </sup>
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            activeClassName='active'
            className='header-navlink'
            to='/history'
          >
            {t('history')}
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            activeClassName='inactive'
            className='header-navlink'
            onClick={() =>
              window.open(
                'https://docs.google.com/forms/d/e/1FAIpQLSdp0PFUYmaK6PmjMcLFXNg4MwGZ3-jo5y7SHR8GU4l33uV4jw/viewform?usp=sf_link',
                '_blank'
              )
            }
            to='/'
          >
            Devis
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <UserMenu></UserMenu>
    </Navbar>
  );
};

export default Header;
